<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="450">
      <template v-slot:activator="{ on, attrs }">
        <div class="card-toolbar" v-bind="attrs" v-on="on">
          <a class="btn btn-primary font-weight-bolder">
            <span class="svg-icon svg-icon-white"
              ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-09-15-014444/theme/html/demo1/dist/../src/media/svg/icons/Communication/Send.svg--><svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <rect x="0" y="0" width="24" height="24" />
                  <path
                    d="M3,13.5 L19,12 L3,10.5 L3,3.7732928 C3,3.70255344 3.01501031,3.63261921 3.04403925,3.56811047 C3.15735832,3.3162903 3.45336217,3.20401298 3.70518234,3.31733205 L21.9867539,11.5440392 C22.098181,11.5941815 22.1873901,11.6833905 22.2375323,11.7948177 C22.3508514,12.0466378 22.2385741,12.3426417 21.9867539,12.4559608 L3.70518234,20.6826679 C3.64067359,20.7116969 3.57073936,20.7267072 3.5,20.7267072 C3.22385763,20.7267072 3,20.5028496 3,20.2267072 L3,13.5 Z"
                    fill="#000000"
                  />
                </g></svg
              ><!--end::Svg Icon--></span
            >

            {{ $t('MENU.REPORT.FINANCE_REPORT.EXPENDITURE') }} +</a
          >
        </div>
      </template>
      <v-card>
        <v-card-title class="headline"> Xarajat +</v-card-title>
        <v-card-text>
          <v-autocomplete
            :items="mainProfitSubCat"
            :success="
              mainIncome !== '' &&
              mainIncome !== null &&
              mainIncome !== undefined
            "
            v-model="mainIncome"
            item-text="name"
            dense
            return-object
            item-value="id"
            label="Asosiy Kirim"
            outlined
          ></v-autocomplete>
          <v-autocomplete
            dense
            @change="changeMainIncomeCategory(mainIncomeCategory)"
            :items="mainIncome.incomecategory_set"
            v-model="mainIncomeCategory"
            :success="
              mainIncomeCategory !== '' &&
              mainIncomeCategory !== null &&
              mainIncomeCategory !== undefined
            "
            item-text="name"
            return-object
            item-value="id"
            no-data-text="Malumot topilmadi"
            label="Kategoriya"
            outlined
          ></v-autocomplete>
          <v-autocomplete
            dense
            :items="subcat"
            v-model="mainIncomeSubCategory"
            :success="
              mainIncomeSubCategory !== '' &&
              mainIncomeSubCategory !== null &&
              mainIncomeSubCategory !== undefined
            "
            no-data-text="Malumot topilmadi"
            :hide-no-data="true"
            item-text="name"
            item-value="id"
            label="SubKategoriya"
            outlined
          ></v-autocomplete>
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Sana"
                dense
                :value="oper_date | dateFilter"
                append-icon="event"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="oper_date"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>

          <v-text-field
            v-model="amount"
            dense
            v-currency="options"
            label="Summa"
            outlined
          ></v-text-field>

          <v-textarea outlined label="Izoh" v-model="comment"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="newExpenditureLoading"
            color="error"
            @click="dialog = false"
          >
            Yopish
          </v-btn>
          <v-btn :disabled="newExpenditureLoading" color="success" @click="send"
            ><i v-if="newExpenditureLoading" class="el-icon-loading"></i>
            Yuborish
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newExpenditureLoading: false,
      mainIncome: '',
      mainIncomeCategory: '',
      mainIncomeSubCategory: '',
      mainIncomeSubCategoryList: [],
      subcat: [],
      comment: '',
      oper_date: '',
      amount: '',
      menu2: false,
      dialog: false
    }
  },
  async mounted() {
    await this.$store.dispatch('getMainProfitSubCategorySpecial')
  },
  computed: {
    mainProfitSubCat() {
      return this.$store.state.requests.mainProfitSubCategorySpecial
    },
    options() {
      return {
        locale: 'zh-ZH',
        currency: [
          this.currencyCode,
          null,
          { prefix: this.prefix, suffix: this.suffix }
        ][this.selectedCurrencyOption],
        valueRange: this.valueRangeEnabled
          ? { min: this.valueRange[0], max: this.valueRange[1] }
          : undefined,
        precision: this.precisionEnabled
          ? this.precisionRangeEnabled
            ? { min: this.precisionRange[0], max: this.precisionRange[1] }
            : this.precisionFixed
          : undefined,
        distractionFree: this.distractionFree
          ? {
              hideNegligibleDecimalDigits: this.hideNegligibleDecimalDigits,
              hideCurrencySymbol: this.hideCurrencySymbol,
              hideGroupingSymbol: this.hideGroupingSymbol
            }
          : false,
        autoDecimalMode: false,
        valueAsInteger: false,
        allowNegative: false
      }
    }
  },
  methods: {
    send() {
      const data = {
        expenditure_amount: parseFloat(this.amount.replace(/,/g, '')),
        oper_date: this.oper_date,
        comment: this.comment,
        expenditure_type: this.mainIncomeSubCategory
      }
      console.log(data)
      this.newExpenditureLoading = true
      this.$store
        .dispatch('createExpenditure', data)
        .then(() => {
          this.newExpenditureLoading = false
          this.mainIncome = ''
          this.mainIncomeSubCategory = ''
          this.amount = ''
          this.oper_date = ''
          this.mainIncomeSubCategory = ''
          this.comment = ''
          this.dialog = false
        })
        .catch((err) => {
          this.newExpenditureLoading = false
          console.error(err)
        })
    },
    changeMainIncomeCategory(val) {
      this.subcat = [...val.incometype_set]
    }
  }
}
</script>

<style></style>
