<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('MENU.REPORT.FINANCE_REPORT.EXPENDITURE') }}
              </h3>
            </div>
            <div class="card-button">
              <create />
            </div>
          </div>
          <div class="card-body">
            <v-data-table
              v-if="!loading"
              :headers="headers"
              :loading="isLoading"
              single-select
              @click:row="rowClick"
              item-key="id"
              loading-text="...."
              :items="getData"
              no-data-text="Malumot kiritilmagan"
              hide-default-footer
              class="row-pointer"
            >
              <template v-slot:[`item.index`]="{ item }">
                {{
                  getData
                    .map(function (x) {
                      return x.id
                    })
                    .indexOf(item.id) + 1
                }}
              </template>
              <template v-slot:[`item.oper_date`]="{ item }">
                {{ item.oper_date | dateFilter }}
              </template>

              <template v-slot:[`item.expenditure_amount`]="{ item }">
                {{ item.expenditure_amount.toLocaleString('zh-ZH') }}
              </template>
            </v-data-table>
            <div class="text-left mt-5">
              <v-pagination
                v-model="currentPage"
                :total-visible="10"
                @input="getPostData(currentPage)"
                :length="Math.ceil(getCount.count / 10)"
              ></v-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
// create
import create from './create'
export default {
  components: {
    create
  },
  data() {
    return {
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      currentPage: 1,
      page: 1
    }
  },
  created() {
    if (this.$route.query.page !== undefined) {
      this.getPostData(parseInt(this.$route.query.page, 10))
      this.currentPage = parseInt(this.$route.query.page, 10)
    } else {
      this.getPostData(this.currentPage)
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.REPORT.TITLE') },
      { title: this.$t('MENU.REPORT.FINANCE_REPORT.TITLE') },
      { title: this.$t('MENU.REPORT.FINANCE_REPORT.EXPENDITURE') }
    ])
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        row.select(false)
      }
    },
    getPostData(page) {
      if (parseInt(this.$router.currentRoute.query.page, 10) !== page) {
        this.$router.push({
          path: this.$router.currentRoute.path,
          query: { page: page }
        })
      }
      this.$store.dispatch('getFinanceExpenditureList', page)
    }
  },
  computed: {
    headers() {
      return [
        { text: '#', value: 'index' },
        {
          text: this.$t('TABLE_HEADER.AMOUNT_EXPENDITURE'),
          value: 'expenditure_type_description.category_info.name'
        },
        {
          text: this.$t('TABLE_HEADER.AMOUNT_EXPENDITURE'),
          value: 'expenditure_amount'
        },
        { text: this.$t('TABLE_HEADER.DATE'), value: 'oper_date' },
        {
          text: this.$t('TABLE_HEADER.EXPENDITURE_TYPE'),
          value: 'goodsservicesdocs.truck_info'
        },
        {
          text: this.$t('TABLE_HEADER.EXPENDITURE_TYPE'),
          value: 'goodsservicesdocs.card_number'
        },
        {
          text: this.$t('TABLE_HEADER.EXPENDITURE_TYPE'),
          value: 'goodsservicesdocs.card_owner'
        },
        {
          text: this.$t('TABLE_HEADER.REG_NUMBER'),
          value: 'goodsservicesdocs.reg_number'
        },
        {
          text: this.$t('TABLE_HEADER.EXPENDITURE_TYPE'),
          value: 'goodsservicesdocs.seller_to_corpcard_cash'
        },
        {
          text: this.$t('TABLE_HEADER.CODE'),
          value: 'mainassets_amortization.bank_account_code'
        },
        {
          text: this.$t('TABLE_HEADER.EXPENDITURE_TYPE'),
          value: 'mainassets_amortization.main_assets_inventar_number'
        },
        {
          text: this.$t('TABLE_HEADER.EXPENDITURE_TYPE'),
          value: 'mainassets_amortization.main_assets_name'
        },
        {
          text: this.$t('TABLE_HEADER.COMMENT'),
          value: 'comment'
        }
      ]
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    getData() {
      return this.$store.state.requests.financeExpenditureList.results
    },
    getCount() {
      return this.$store.state.requests.financeExpenditureList
    }
  }
}
</script>

<style scoped></style>
