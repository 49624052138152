var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"450"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"card-toolbar"},'div',attrs,false),on),[_c('a',{staticClass:"btn btn-primary font-weight-bolder"},[_c('span',{staticClass:"svg-icon svg-icon-white"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","width":"24px","height":"24px","viewBox":"0 0 24 24","version":"1.1"}},[_c('g',{attrs:{"stroke":"none","stroke-width":"1","fill":"none","fill-rule":"evenodd"}},[_c('rect',{attrs:{"x":"0","y":"0","width":"24","height":"24"}}),_c('path',{attrs:{"d":"M3,13.5 L19,12 L3,10.5 L3,3.7732928 C3,3.70255344 3.01501031,3.63261921 3.04403925,3.56811047 C3.15735832,3.3162903 3.45336217,3.20401298 3.70518234,3.31733205 L21.9867539,11.5440392 C22.098181,11.5941815 22.1873901,11.6833905 22.2375323,11.7948177 C22.3508514,12.0466378 22.2385741,12.3426417 21.9867539,12.4559608 L3.70518234,20.6826679 C3.64067359,20.7116969 3.57073936,20.7267072 3.5,20.7267072 C3.22385763,20.7267072 3,20.5028496 3,20.2267072 L3,13.5 Z","fill":"#000000"}})])])]),_vm._v(" "+_vm._s(_vm.$t('MENU.REPORT.FINANCE_REPORT.EXPENDITURE'))+" +")])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Xarajat +")]),_c('v-card-text',[_c('v-autocomplete',{attrs:{"items":_vm.mainProfitSubCat,"success":_vm.mainIncome !== '' &&
            _vm.mainIncome !== null &&
            _vm.mainIncome !== undefined,"item-text":"name","dense":"","return-object":"","item-value":"id","label":"Asosiy Kirim","outlined":""},model:{value:(_vm.mainIncome),callback:function ($$v) {_vm.mainIncome=$$v},expression:"mainIncome"}}),_c('v-autocomplete',{attrs:{"dense":"","items":_vm.mainIncome.incomecategory_set,"success":_vm.mainIncomeCategory !== '' &&
            _vm.mainIncomeCategory !== null &&
            _vm.mainIncomeCategory !== undefined,"item-text":"name","return-object":"","item-value":"id","no-data-text":"Malumot topilmadi","label":"Kategoriya","outlined":""},on:{"change":function($event){return _vm.changeMainIncomeCategory(_vm.mainIncomeCategory)}},model:{value:(_vm.mainIncomeCategory),callback:function ($$v) {_vm.mainIncomeCategory=$$v},expression:"mainIncomeCategory"}}),_c('v-autocomplete',{attrs:{"dense":"","items":_vm.subcat,"success":_vm.mainIncomeSubCategory !== '' &&
            _vm.mainIncomeSubCategory !== null &&
            _vm.mainIncomeSubCategory !== undefined,"no-data-text":"Malumot topilmadi","hide-no-data":true,"item-text":"name","item-value":"id","label":"SubKategoriya","outlined":""},model:{value:(_vm.mainIncomeSubCategory),callback:function ($$v) {_vm.mainIncomeSubCategory=$$v},expression:"mainIncomeSubCategory"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Sana","dense":"","value":_vm._f("dateFilter")(_vm.oper_date),"append-icon":"event","readonly":"","outlined":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.oper_date),callback:function ($$v) {_vm.oper_date=$$v},expression:"oper_date"}})],1),_c('v-text-field',{directives:[{name:"currency",rawName:"v-currency",value:(_vm.options),expression:"options"}],attrs:{"dense":"","label":"Summa","outlined":""},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),_c('v-textarea',{attrs:{"outlined":"","label":"Izoh"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.newExpenditureLoading,"color":"error"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Yopish ")]),_c('v-btn',{attrs:{"disabled":_vm.newExpenditureLoading,"color":"success"},on:{"click":_vm.send}},[(_vm.newExpenditureLoading)?_c('i',{staticClass:"el-icon-loading"}):_vm._e(),_vm._v(" Yuborish ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }